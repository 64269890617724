// import image1 from "../img/t-image1.png";
// import image2 from "../img/t-image2.jpg";
// import image3 from "../img/t-image3.jpg";
import image4 from "../img/a12.jpeg";
import image5 from "../img/a11.jpeg";
import image6 from "../img/a10.jpeg"
import veg1Img from "../img/bg2.jfif"
import veg2Img from "../img/veg.jfif"
// slider
import Main4 from "../img/main4.png"
import Main2 from "../img/main2.png"
import Main3 from "../img/main3.png"
// import SliderImg1 from "../../src/img/slider1.webp"
// import SliderImg2 from "../../src/img/slider2.webp"
// import SliderImg3 from "../../src/img/slider5.webp"

import aboutImg from "../img/ora.jfif"



// Orange Photo
import orange1 from "../../src/img/images/Orange/Orange1.jpg"
import orange2 from "../../src/img/images/Orange/Orange2.jpg"
import orange3 from "../../src/img/images/Orange/Orange3.jpg"
import orange4 from "../../src/img/images/Orange/Orange4.jpg"
import orange5 from "../../src/img/images/Orange/Orange5.jpg"
import orange6 from "../../src/img/images/Orange/Orange6.jpg"

// Vegetables Photo
import Artichoke from "../../src/img/images/Vegetables/Artichoke.webp"
import BellPepper from "../../src/img/images/Vegetables/BellPepper.webp"
import Artichoke2 from "../../src/img/images/Vegetables/Artichoke2.webp"
import Botato from "../../src/img/images/Vegetables/Botato.webp"
import Botato2 from "../../src/img/images/Vegetables/Botato2.webp"


import Cabbage from "../../src/img/images/Vegetables/Cabbage.webp"
import Cabbage2 from "../../src/img/images/Vegetables/Cabbage2.webp"
import Chili from "../../src/img/images/Vegetables/Chili.webp"
import Chili2 from "../../src/img/images/Vegetables/Chili2.webp"
import Garlic from "../../src/img/images/Vegetables/Garlic.webp"
import Garlic2 from "../../src/img/images/Vegetables/Garlic2.webp"
import Lettuce from "../../src/img/images/Vegetables/Lettuce.webp"
import Lettuce2 from "../../src/img/images/Vegetables/Lettuce2.webp"
import BellPepper1 from "../../src/img/images/Vegetables/BellPepper.webp"
import BellPepper2 from "../../src/img/images/Vegetables/BellPepper2.webp"

import SweetBotato from "../../src/img/images/Vegetables/SweetBotato.webp"
import SweetBotato2 from "../../src/img/images/Vegetables/SweetBotato2.webp"
import Tomato from "../../src/img/images/Vegetables/Tomato.webp"
import Tomato2 from "../../src/img/images/Vegetables/Tomato2.webp"
import YellowOnion from "../../src/img/images/Vegetables/YellowOnion.webp"
import YellowOnion2 from "../../src/img/images/Vegetables/YellowOnion2.webp"
import redOnion1 from "../../src/img/images/Vegetables/redOnion1.webp"
import redOnion2 from "../../src/img/images/Vegetables/redOnion2.webp"


//Fruits

import lemon1 from "../../src/img/images/Fruits/lemon1.webp"
import lemon2 from "../../src/img/images/Fruits/lemon2.webp"
import date1 from "../../src/img/images/Fruits/dates1.webp"
import date2 from "../../src/img/images/Fruits/dates2.webp"
import grapes1 from "../../src/img/images/Fruits/grapes1.webp"
import grapes2 from "../../src/img/images/Fruits/grapes2.webp"
import mandarin1 from "../../src/img/images/Fruits/mandarin1.webp"
import mandarin2 from "../../src/img/images/Fruits/mandarin2.webp"
import strawberry1 from "../../src/img/images/Fruits/strawberry1.webp"
import strawberry2 from "../../src/img/images/Fruits/strawberry2.webp"
import watermelon1 from "../../src/img/images/Fruits/watermelon1.webp"
import watermelon2 from "../../src/img/images/Fruits/watermelon2.webp"
import grapefruit1 from "../../src/img/images/Fruits/grapefruit1.webp"
import pomgrante1 from "../../src/img/images/Fruits/pomgrante1.webp"
import pomgrante2 from "../../src/img/images/Fruits/pomgrante2.webp"
import mango1 from "../../src/img/images/Fruits/mango1.webp"
import mango2 from "../../src/img/images/Fruits/mango2.webp"

// Frozen

import Apricot from "../../src/img/images/Frozen/Apricot.webp"
import ArtichokeZN from "../../src/img/images/Frozen/ArtichokeZN.webp"
import BroadBeans from "../../src/img/images/Frozen/BroadBeans.webp"
import Brocoli from "../../src/img/images/Frozen/Brocoli.webp"
import Cantaloupe from "../../src/img/images/Frozen/Cantaloupe.webp"
import Carrot from "../../src/img/images/Frozen/Carrot.webp"
import CauliFlower from "../../src/img/images/Frozen/CauliFlower.webp"
import GrapsZN from "../../src/img/images/Frozen/GrapsZN.webp"
import GreenBeans from "../../src/img/images/Frozen/GreenBeans.webp"
import Guava from "../../src/img/images/Frozen/guava1.webp"
import MangoZN from "../../src/img/images/Frozen/MangoZN.webp"
import MixedVegetables from "../../src/img/images/Frozen/MixedVegetables.webp"
import MixedVegetables2 from "../../src/img/images/Frozen/MixedVegetables2.webp"
import Molokhia from "../../src/img/images/Frozen/Molokhia.webp"
import Okra from "../../src/img/images/Frozen/Okra.webp"
import Okra2 from "../../src/img/images/Frozen/Okra2.webp"
import PapperZN from "../../src/img/images/Frozen/PapperZN.webp"
import Peas from "../../src/img/images/Frozen/Peas.webp"
import PomegranateZN from "../../src/img/images/Frozen/PomegranateZN.webp"
import Potato from "../../src/img/images/Frozen/Potato.webp"
import Spinach from "../../src/img/images/Frozen/Spinach.webp"
import Spinach2 from "../../src/img/images/Frozen/Spinach2.webp"
import StrawberriesZN from "../../src/img/images/Frozen/StrawberriesZN.webp"
import Taro from "../../src/img/images/Frozen/Taro.webp"
import VineLeaves from "../../src/img/images/Frozen/VineLeaves.webp"
import WatermelonZN from "../../src/img/images/Frozen/WatermelonZN.webp"






export const SliderData = [
  {
    image: Main4,
    review:
      "Infinity Agro Coompany has an established network with various channel partners and corporate associates. The company is committed to providing high-quality agro crops to its customers.",
    bgColor: "rgba(240, 248, 255, 0.6)"
  },
  {
    image: Main2,
    review: 'Welcome to INFINITY for Agriculture Crops , a leading player in the agricultural export industry, dedicated to supplying high-quality agricultural crops to global markets ',
    bgColor: "rgba(240, 248, 255, 0.6)"
  },
  {
    image : Main3,
    review:' At INFINITY for Agriculture Crops , we envision a future where our agricultural expertise and sustainable practices empower communities and nourish the world',
    bgColor: "rgba(240, 248, 255, 0.6)"
  }
];
export const aboutData = 
  {
    image:aboutImg,
    whyOur: "With all the challenges facing companies today, our first goal is always customer satisfaction. We are a company that seeks to provide high-quality professional services, developing and improving them through customer experiences. In addition to providing the best price for the customer and always enhancing the confidence of our customers.We always strive for the best...",
    about:
      "Welcome to INFINITY for Agriculture Crops , a leading player in the agricultural export industry, dedicated to supplying high-quality agricultural crops to global markets. Established with a commitment to excellence, sustainability, and customer satisfaction, we take pride in contributing to the world's demand for premium agricultural produce.",
    mission: "At INFINITY for Agriculture Crops , our mission is to [briefly state the overarching goal of your company, e.g., 'to deliver fresh and sustainably grown agricultural products to markets worldwide']. We are driven by a passion for agriculture, a dedication to quality, and a responsibility to our communities and the environment.",
    vision:"At INFINITY for Agriculture Crops , we envision a future where our agricultural expertise and sustainable practices empower communities and nourish the world. Our vision is to be a beacon of innovation and integrity in the agricultural export industry, contributing to global food security and fostering a sustainable, interconnected world."  }
export const OrangeData = 
{
  smallDesc:"",
  fullDesc: "",
  images: [orange1,orange2,orange3,orange4,orange5,orange6]
}



export const testimonialsData = [
  {
    image: image4,
    review:
      "I made the right choice by choosing the Fruits and by choosing the right plan and program I already achieved my ideal body!",
    name: 'Fruit',
    status : 'banana'
  },
  {
    image: image5,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'fruit',
    status: 'melon'
  },
  {
    image : image6,
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'fruit',
    status: "kaka"
  }
];

export const VegetablesData = [
  {
    image: [SweetBotato,SweetBotato2],
    review:
    "Sweet potatoes, scientifically known as Ipomoea batatas, are a nutritious and versatile root vegetable.Sweet potatoes are a great source of essential nutrients, including vitamins A and C, potassium, and fiber.The orange-fleshed varieties are particularly rich in beta-carotene, which is converted into vitamin A in the body.",
    name : 'Sweet Botato',
    smDesc: "Sweet potatoes, scientifically known as Ipomoea batatas, are a nutritious and versatile root vegetable."
    ,available:"August till March"
  },
  {
    image: [Botato,Botato2],
    review:
    "Potatoes are a good source of essential nutrients such as vitamin C, potassium, and vitamin B6.They also provide dietary fiber, especially if consumed with the skin.",
    name : 'Potato',
    smDesc: "Potatoes are a good source of essential nutrients such as vitamin C, potassium, and vitamin B6.They also provide dietary fiber, especially if consumed with the skin."
    ,available:"June till january "
  },
  {
    image : [redOnion1,redOnion2],
    review:
    "Red onions have a milder and sweeter flavor compared to yellow or white onions.They add a crisp and tangy taste to dishes when consumed raw and become sweeter when cooked.",
    name : 'Red Onions',
    smDesc: "Red onions have a milder and sweeter flavor compared to yellow or white onions.They add a crisp and tangy taste to dishes when consumed raw and become sweeter when cooked."
    ,available:"April till September"
  },
  {
    image : [YellowOnion],
    review:
    "Yellow onions have a milder and sweeter flavor compared to yellow or white onions.They add a crisp and tangy taste to dishes when consumed raw and become sweeter when cooked.",
    name : 'Yellow Onions',
    smDesc: "Yellow onions have a milder and sweeter flavor compared to yellow or white onions.They add a crisp and tangy taste to dishes when consumed raw and become sweeter when cooked."
    ,available:"Mid February till August"
  },
  {
    image : [Garlic,Garlic2],
    review:
    "Garlic has a pungent and spicy flavor when raw, but it becomes milder and sweeter when cooked.It adds depth and complexity to a wide range of dishes.Garlic is rich in nutrients such as allicin, sulfur compounds, vitamin C, vitamin B6, manganese, and selenium.It is known for its potential health benefits, including immune system support and cardiovascular health.",
    name : 'Gralic',
    smDesc: "Garlic has a pungent and spicy flavor when raw, but it becomes milder and sweeter when cooked.It adds depth and complexity to a wide range of dishes."
    ,available:"Mid April till August"
  }
  ,
  {
    image : [Chili2,Chili],
    review:
    "Chili peppers are a good source of vitamins, especially vitamin C, as well as vitamin A, potassium, and antioxidants.Some studies suggest that capsaicin may have various health benefits, including metabolism-boosting properties.",
    name : 'Chili Pepper',
    smDesc: "Chili peppers are a good source of vitamins, especially vitamin C, as well as vitamin A, potassium, and antioxidants."
    ,available:"July till August"
  },
  {
    image : [BellPepper1,BellPepper2],
    review:
    "Bell peppers are available in a range of colors,including red, yellow, orange, green, and sometimes purple or brown, depending on the ripeness.Each color has its own distinct flavor and nutritional profile.Bell peppers are low in calories and rich in essential nutrients,including vitamin C, vitamin A, vitamin B6, and various antioxidants. The different colors of bell peppers indicate variations in nutrient content.",
    name : 'Bell Pepper',
    smDesc: "Bell peppers are available in a range of colors,including red, yellow, orange, green, and sometimes purple or brown, depending on the ripeness."
    ,available:"July till August"
  },
  {
    image : [Artichoke,Artichoke2],
    review:
    "Artichokes have a mild, slightly sweet, and nutty flavor.The tender inner leaves and heart are prized for their delicate taste and smooth texture.Artichokes are a good source of dietary fiber, vitamins C and K, folate, and various minerals.They are also known for their high antioxidant content.",
    name : 'Artichokes ',
    smDesc: "Artichokes have a mild, slightly sweet, and nutty flavor.The tender inner leaves and heart are prized for their delicate taste and smooth texture."
    ,available:"March till Novamber"
  },
  {
    image : [Tomato,Tomato2],
    review:
    "Tomatoes are a staple in many cuisines globally.They can be consumed fresh in salads, sandwiches, and salsas or cooked in sauces, soups, stews, and a variety of dishes.The antioxidants, vitamins, and minerals in tomatoes contribute to potential health benefits,including heart health, skin health, and anti-inflammatory effects.",
    name : 'Tomatoes ',
    smDesc: "Tomatoes are a staple in many cuisines globally.They can be consumed fresh in salads, sandwiches, and salsas or cooked in sauces, soups, stews, and a variety of dishes."
    ,available:"May till October"
  },
  {
    image : [Cabbage,Cabbage2],
    review:
    "Cabbage has a mild, slightly peppery flavor. The taste can vary slightly between different varieties,with red cabbage often having a deeper and earthier flavor.Cabbage is known for its potential health benefits, including anti-inflammatory properties,digestive support, and its contribution to heart health.",
    name : 'Cabbage ',
    smDesc: "Cabbage has a mild, slightly peppery flavor. The taste can vary slightly between different varieties,with red cabbage often having a deeper and earthier flavor."
    ,available:"August till March"
  },
  {
    image : [Lettuce,Lettuce2],
    review:
    "Alcappuccio lettuce is characterized by its deeply cut, frilly leaves, giving it an attractive and decorative appearance.The leaves may resemble the appearance of oak leaves.This type of lettuce typically has a mild and slightly sweet flavor,making it a popular choice for salads and as a garnish.",
    name : 'Alcappuccio lettuce ',
    smDesc: "Alcappuccio lettuce is characterized by its deeply cut, frilly leaves, giving it an attractive and decorative appearance.The leaves may resemble the appearance of oak leaves."
    ,available:"May till October"
  },
 
];

export const fruitsData = [
  {
    image: [date1,date2],
    review:"Dates have a natural sweetness and a rich, caramel-like flavor.The taste can vary depending on the variety, with Medjool dates often considered particularly sweet and luscious.Dates are a good source of energy providing natural sugars fiber, and essential minerals such as potassium, magnesium, and iron.They are a nutritious and natural sweetener.", 
    name : 'Dates',
    smDesc:"Dates have a natural sweetness and a rich, caramel-like flavor.The taste can vary depending on the variety, with Medjool dates often considered particularly sweet and luscious."
    ,available:" October till May"
  },
  {
    image: [watermelon1,watermelon2],
    review:"Watermelon has a sweet and mild flavor, with a hint of crispness.The taste can vary slightly between different varieties. While low in calories, watermelon is rich in vitamins A and C.It also contains antioxidants like lycopene, which is responsible for the fruit's vibrant red color.",
    name: 'Watermelon',
    smDesc:"Watermelon has a sweet and mild flavor, with a hint of crispness.The taste can vary slightly between different varieties."
    ,available:" July till August"
  },
  {
    image: [mango1,mango2],
    review:'Mangoes have a sweet, tropical flavor with a unique combination of sweetness and acidity.The taste can vary between different varieties.Mangoes are rich in vitamins, particularly vitamin C and vitamin A.They also provide dietary fiber, potassium, and antioxidants.',
    name: 'Mango',
    smDesc:"Mangoes have a sweet, tropical flavor with a unique combination of sweetness and acidity.The taste can vary between different varieties."
    ,available:" July till November"
  },
  {
    image: [grapes1,grapes2],
    review:' Grapes have a naturally sweet and juicy flavor.Red grapes can be slightly more tart, while green grapes tend to be sweeter.Grapes are rich in vitamins C and K, antioxidants, and dietary fiber.The skin of red grapes contains resveratrol, a compound associated with potential health benefit',
    name: 'Grapes',
    smDesc:"Grapes have a naturally sweet and juicy flavor.Red grapes can be slightly more tart, while green grapes tend to be sweeter."
    ,available:" May till September"
  },
  {
    image: [grapefruit1,grapefruit1],
    review:'Grapefruit has a distinctive sweet and tangy flavor with a hint of bitterness.The taste can vary depending on the specific variety.Pomegranates are rich in antioxidants, particularly polyphenols and vitamin C.They also provide dietary fiber and are considered a nutritious addition to a balanced diet.',
    name: 'Grapefruit',
    smDesc:"Grapefruit has a distinctive sweet and tangy flavor with a hint of bitterness.The taste can vary depending on the specific variety."

    ,available:" October till January"
  },
  {
    image: [pomgrante1,pomgrante2],
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'Pomegranate',
    smDesc:"Pomegranates are rich in antioxidants, particularly polyphenols and vitamin C.They also provide dietary fiber and are considered a nutritious addition to a balanced diet."
    ,available:" September till December"
  }
  ,
  {
    image: [mandarin1,mandarin2],
    review:' Mandarins are known for their sweet and tangy flavor.They are typically easier to peel than some other citrus fruits, making them a convenient and popular snack.Mandarins are a good source of vitamin C, fiber, and various antioxidants. They contribute to overall health and immune system support.',
    name: 'Mandarin',
    smDesc:"Mandarins are known for their sweet and tangy flavor.They are typically easier to peel than some other citrus fruits, making them a convenient and popular snack."
    ,available:" October till March"
  }
  ,
  {
    image: [lemon1,lemon2],
    review:'Lemons have a sour and tangy flavor due to their high citric acid content.The juice and zest of lemons are commonly used to add acidity and brightness to various dishes.Lemons are a good source of vitamin C, potassium, and dietary fiber.They also contain antioxidants and have been associated with various health benefits.',
    name: 'Lemon',
    smDesc:"Lemons have a sour and tangy flavor due to their high citric acid content.The juice and zest of lemons are commonly used to add acidity and brightness to various dishes."
    ,available:" Mid October till October  ,"
  }
  ,
  {
    image: [strawberry1,strawberry2],
    review:'Strawberries are known for their sweet and juicy taste.The flavor can vary slightly depending on the specific variety and ripeness.Strawberries are rich in vitamin C, manganese, folate, potassium, and antioxidants.They are also low in calories, making them a nutritious and delicious snack.',
    name: 'Strawberries ',
    smDesc:"Strawberries are known for their sweet and juicy taste.The flavor can vary slightly depending on the specific variety and ripeness."
    ,available:" November till February"
  }
];

//Frozen
export const frozenData = [
  {
    image: [Apricot],
    review:
    "Frozen Apricots are delicious, juicy stone fruits with a sweet flavor and vibrant orange color. They are packed with nutrients and offer several health benefits, making them a popular dietary addition.",
    name : 'Apricot',
    smDesc: "Apricots are delicious, juicy stone fruits with a sweet flavor and vibrant orange color. They are packed with nutrients and offer several health benefits, making them a popular dietary addition."
  },
  {
    image: [ArtichokeZN],
    review: "Frozen artichokes are a convenient and delicious way to enjoy the benefits of this unique vegetable. They are readily available year-round, require minimal preparation, and offer several nutritional advantages",
    name : 'Artichoke',
    smDesc: "Frozen artichokes are a convenient and delicious way to enjoy the benefits of this unique vegetable. They are readily available year-round, require minimal preparation, and offer several nutritional advantages",
  
  },
  {
    image : [BroadBeans],
    review:"Frozen broad beans are a convenient and readily available option for enjoying the benefits of this nutritious legume. ",
    name : 'Broad Beans',
    smDesc:"Frozen broad beans are a convenient and readily available option for enjoying the benefits of this nutritious legume. ",
  
  },
  {
    image : [Brocoli],
    review:"Frozen broccoli is a convenient and nutritious way to enjoy this cruciferous vegetable. Frozen broccoli is often harvested at peak ripeness and then blanched and flash frozen to preserve nutrients.",
    name : 'Broccoli',
    smDesc:"Frozen broccoli is a convenient and nutritious way to enjoy this cruciferous vegetable. Frozen broccoli is often harvested at peak ripeness and then blanched and flash frozen to preserve nutrients.",
  
  },
  {
    image : [Cantaloupe],
    review:"Frozen cantaloupe offers a delicious and convenient way to enjoy this sweet and juicy melon year-round. Its vibrant orange color and refreshing flavor make it a perfect addition to smoothies, desserts, and even savory dishes.",
    name : 'Cantaloupe',
    smDesc:"Frozen cantaloupe offers a delicious and convenient way to enjoy this sweet and juicy melon year-round. Its vibrant orange color and refreshing flavor make it a perfect addition to smoothies, desserts, and even savory dishes.",
  
  }
  ,
  {
    image : [Carrot],
    review: "Frozen carrots are a versatile and convenient way to enjoy the benefits of this delicious and nutritious vegetable all year round. They offer several advantages over fresh carrots, making them a popular choice for busy families and individuals.",
    name : 'Carrot',
    smDesc: "Frozen carrots are a versatile and convenient way to enjoy the benefits of this delicious and nutritious vegetable all year round. They offer several advantages over fresh carrots, making them a popular choice for busy families and individuals.",
  
  },
  {
    image : [CauliFlower],
    review: "Frozen cauliflower offers a convenient and versatile way to enjoy the numerous benefits of this cruciferous vegetable. Whether you're short on time, lacking fresh produce access, or simply looking for a budget-friendly option, frozen cauliflower is an excellent choice.",
    name : 'CauliFlower',
    smDesc: "Frozen cauliflower offers a convenient and versatile way to enjoy the numerous benefits of this cruciferous vegetable."
  
  },
  {
    image : [GrapsZN],
    review: "Frozen Graps is a unique and delicious snack that's perfect for any occasion. It's more than just a simple frozen grape; it's an experience that bursts with flavor and fun. Imagine the satisfying crunch of a frozen grape, followed by the juicy explosion of its natural sweetness. Every bite is a refreshing escape from the ordinary, leaving you wanting more.",
    name : 'Graps ',
    smDesc: "Frozen Graps is a unique and delicious snack that's perfect for any occasion. It's more than just a simple frozen grape; it's an experience that bursts with flavor and fun. Imagine the satisfying crunch of a frozen grape, followed by the juicy explosion of its natural sweetness. Every bite is a refreshing escape from the ordinary, leaving you wanting more."
  },
  {
    image : [GreenBeans],
    review:
    "Frozen green beans are a convenient and healthy way to add vegetables to your diet. They are packed with essential nutrients and offer a vibrant pop of color to any dish.",
    name : 'GreenBeans ',
    smDesc: "Frozen green beans are a convenient and healthy way to add vegetables to your diet. They are packed with essential nutrients and offer a vibrant pop of color to any dish."
  },
  {
    image : [Guava],
    review:"Frozen guava is a delicious and convenient way to enjoy the vibrant flavor and health benefits of this tropical fruit. It retains the sweetness, tangy notes, and vibrant pink color of fresh guava, allowing you to incorporate it into your diet year-round, regardless of the season.",
    name : 'Guava ',
    smDesc:"Frozen guava is a delicious and convenient way to enjoy the vibrant flavor and health benefits of this tropical fruit. It retains the sweetness, tangy notes, and vibrant pink color of fresh guava, allowing you to incorporate it into your diet year-round, regardless of the season."
  },
  {
    image : [MangoZN],
    review:"Frozen mango is a year-round delight, offering a burst of tropical flavor and a wealth of nutrients. It captures the sweet essence of perfectly ripe mangoes, preserving their vibrant color, delicious taste, and health benefits.",
    name : 'Mango ',
    smDesc: "Frozen mango is a year-round delight, offering a burst of tropical flavor and a wealth of nutrients. It captures the sweet essence of perfectly ripe mangoes, preserving their vibrant color, delicious taste, and health benefits."
  },
  {
    image : [MixedVegetables,MixedVegetables2],
    review: "Frozen mixed vegetables are typically harvested at their peak ripeness and quickly frozen, preserving their vitamins, minerals, and fiber. This allows you to enjoy the same nutritional benefits as fresh vegetables, even when they're not in season.",
    name : 'MixedVegetables',
    smDesc: "Frozen mixed vegetables are typically harvested at their peak ripeness and quickly frozen, preserving their vitamins, minerals, and fiber. This allows you to enjoy the same nutritional benefits as fresh vegetables, even when they're not in season."
  },
  {
    image : [Molokhia],
    review: "Molokhia, also known as Jew's mallow or jute mallow, is a leafy green vegetable prized for its rich flavor, vibrant green color, and unique mucilaginous texture. Traditionally, preparing molokhia involves a lengthy process of chopping the fresh leaves and simmering them for hours.",
    name : 'Molokhia ',
    smDesc: "Molokhia, also known as Jew's mallow or jute mallow, is a leafy green vegetable prized for its rich flavor, vibrant green color, and unique mucilaginous texture. Traditionally, preparing molokhia involves a lengthy process of chopping the fresh leaves and simmering them for hours."
  },
  {
    image : [Okra,Okra2],
    review: "Frozen okra offers a convenient and accessible way to enjoy the unique flavor and nutritional benefits of this versatile vegetable year-round. Whether you're short on fresh okra or simply want a quick and easy option, frozen okra provides a readily available alternative.",
    name : 'Okra ',
    smDesc: "Frozen okra offers a convenient and accessible way to enjoy the unique flavor and nutritional benefits of this versatile vegetable year-round. Whether you're short on fresh okra or simply want a quick and easy option, frozen okra provides a readily available alternative."
  },

  {
    image : [PapperZN],
    review:"Frozen peppers are a delicious addition to a crudités platter with veggie dip; they have a distinctive crunch and a beautiful sweetness ",
    name : 'Papper ',
    smDesc:"Frozen peppers are a delicious addition to a crudités platter with veggie dip; they have a distinctive crunch and a beautiful sweetness "
  },
  {
    image : [Peas],
    review:"Frozen peas, humble and unassuming, are a nutritional powerhouse waiting to be unleashed. These little green spheres are deceptively simple, yet they pack a punch with vitamins, minerals, and other health benefits.",
    name : 'Peas',
    smDesc:"Frozen peas, humble and unassuming, are a nutritional powerhouse waiting to be unleashed. These little green spheres are deceptively simple, yet they pack a punch with vitamins, minerals, and other health benefits."
  },
  {
    image : [PomegranateZN],
    review:"Frozen pomegranate is not just a delicious and nutritious ingredient; it's a symbol of health and vitality. Its vibrant color and rich history add a touch of exoticism to your meals, making it a perfect choice for impressing guests or simply adding a touch of luxury to your everyday life.",
    name : 'Pomegranate',
    smDesc: "Frozen pomegranate is not just a delicious and nutritious ingredient; it's a symbol of health and vitality. Its vibrant color and rich history add a touch of exoticism to your meals, making it a perfect choice for impressing guests or simply adding a touch of luxury to your everyday life."
    
  },
  {
    image : [Potato],
    review:"The frozen potato, often unassuming and overlooked, is a culinary hero in disguise. From the crispy golden fries that grace our plates at restaurants to the comforting mashed potatoes that warm our souls on a cold day, the frozen potato plays a versatile and essential role in our kitchens.",
    name : 'Potato',
    smDesc: "The frozen potato, often unassuming and overlooked, is a culinary hero in disguise. From the crispy golden fries that grace our plates at restaurants to the comforting mashed potatoes that warm our souls on a cold day, the frozen potato plays a versatile and essential role in our kitchens."
    
  },
  {
    image : [Spinach],
    review:"Frozen spinach is a versatile and convenient way to incorporate the numerous health benefits of this leafy green into your diet. Whether you're a seasoned chef or a busy individual looking for quick and healthy meals, frozen spinach offers a variety of advantages.    ",
    name : 'Spinach ',
    smDesc: "Frozen spinach is a versatile and convenient way to incorporate the numerous health benefits of this leafy green into your diet. Whether you're a seasoned chef or a busy individual looking for quick and healthy meals, frozen spinach offers a variety of advantages.    "
  },
  {
    image : [StrawberriesZN],
    review:"Frozen strawberries are a wonderful way to enjoy the deliciousness and health benefits of this beloved fruit any time of year. They offer a convenient, affordable, and often more sustainable option than fresh strawberries, especially when they are out of season.",
    name : 'Strawberries',
    smDesc: "Frozen strawberries are a wonderful way to enjoy the deliciousness and health benefits of this beloved fruit any time of year. They offer a convenient, affordable, and often more sustainable option than fresh strawberries, especially when they are out of season."
    
  },
  {
    image : [Taro],
    review:"Frozen taro is a convenient and versatile ingredient that has gained increasing popularity in recent years. This delicious and nutritious tuber, native to Southeast Asia and the Pacific Islands, is known for its unique flavor and texture. By freezing taro, you can enjoy its benefits year-round, unlocking a world of culinary possibilities.",
    name : 'Taro',
    smDesc: "Frozen taro is a convenient and versatile ingredient that has gained increasing popularity in recent years. This delicious and nutritious tuber, native to Southeast Asia and the Pacific Islands, is known for its unique flavor and texture. By freezing taro, you can enjoy its benefits year-round, unlocking a world of culinary possibilities."
  },
  {
    image : [VineLeaves],
    review:"Frozen vine leaves offer a quick and easy way to enjoy the delicious and versatile flavors of this classic Mediterranean ingredient. Whether you're a seasoned cook or a kitchen novice, frozen vine leaves make it easier than ever to create authentic and flavorful dishes.",
    name : 'VineLeaves',
    smDesc: "Frozen vine leaves offer a quick and easy way to enjoy the delicious and versatile flavors of this classic Mediterranean ingredient. Whether you're a seasoned cook or a kitchen novice, frozen vine leaves make it easier than ever to create authentic and flavorful dishes."
    
  },
  {
    image : [WatermelonZN],
    review:"Frozen watermelon is the perfect answer to hot weather cravings. It's a healthy, low-calorie snack that's packed with vitamins and antioxidants. Unlike sugary ice pops or frozen yogurt, frozen watermelon offers a guilt-free way to cool down and satisfy your sweet tooth.",
    name : 'Watermelon',
    smDesc: "Frozen watermelon is the perfect answer to hot weather cravings. It's a healthy, low-calorie snack that's packed with vitamins and antioxidants. Unlike sugary ice pops or frozen yogurt, frozen watermelon offers a guilt-free way to cool down and satisfy your sweet tooth."
    
  },

  
]

// export const vegetablesData = [
//   {
//     image: [veg1Img,veg2Img,image3],
//     review:
//     "I made the right choice by choosing the Fruits and by choosing the right plan and program I already achieved my ideal body!",
//     name: 'Carrot',
//     status : 'Availabile'
//   },
//   {
//     image: [veg1Img,veg2Img,image3],
//     review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
//     name: 'betengan',
//     status: 'Availabile'
//   },
//   {
//     image: [veg1Img,veg2Img,image3],
//     review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
//     name: 'kheyar',
//     status: "Availabile"
//   },
//   {
//     image: [veg1Img,veg2Img,image3],
//     review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
//     name: 'tomato',
//     status: "Availabile"
//   },
//   {
//     image: [veg1Img,veg2Img,image3],
//     review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
//     name: 'potato',
//     status: "Availabile"
//   },
//   {
//     image: [veg1Img,veg2Img,image3],
//     review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
//     name: 'garger',
//     status: "Availabile"
//   },
// ];


export const footerData = [
  {
    aboutDesc: veg1Img,
    aboutLocation:
     "Infinity Agro Coompany has an established network with various channel partners and corporate associates. The company is committed to providing high-quality agro products and services to its customers.",
   status : 'carrot'
  },
  {
    image: veg2Img,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'Vegetables',
    status: 'potato'
  },
  {
    image : veg1Img,
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'Vegetables',
    status: "tomato"
  }
];