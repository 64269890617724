import React from 'react'
import './One.css'
import { OrangeData } from '../../data/testimonialsData'
// import background from "../../img/asac.png"
// import bg from "../../img/oneBg.jpg"
// import bg2 from "../../img/a13.jpeg"
// import BlackBerry3 from "../../img/images/Fruits/BlackBerry3.jpg"

export const One = () => {
  return (
    <div className='orange' style={{backgroundColor:"#EE7214"}}>
      <img src={OrangeData.images[0]} className='fr-main-bg' />
      <p className='main-fr-head'>Orange</p>
      <div className='orange-container'>
      <div className='orange-type'>
          <p className='orange-title'>Orange</p>
          
          <div className='orange-descs'>
           <div className='orange-desc'>
           <h3 className='f-desc-h'> Types: </h3>
            <p className='f-desc-p'> Baladi , Valencia , Nevel
          </p>
           </div>
           <div className='orange-desc'>
           <h3 className='f-desc-h'> Available: </h3>
            <p className='f-desc-p'> November, July, December
          </p>
           </div>
      
           <div className='orange-desc'>
           <h3 className='f-desc-h'> Desc: </h3>
            <p className='f-desc-p'>  Oranges have a sweet and tangy flavor with a refreshing juiciness.
 The taste can vary slightly between different orange varieties.
Oranges are an excellent source of vitamin C, providing a significant portion of the recommended daily intake.
 They also contain fiber, potassium, vitamin A, and various antioxidants.
          </p>
           </div>
          </div>

          <div className='orange-images'>
            <img src={OrangeData.images[0]} />
            <img src={OrangeData.images[1]} />
            <img src={OrangeData.images[2]}/>
          </div>
        </div>

      
      </div>
    </div>
  )
}
