import React from 'react'
import './About.css'

import { aboutData } from '../../data/testimonialsData'


export const About = () => {
  return (
    <div className='About' id='About' >

    <div className='about-Mheader'>
    <p className='about-h' >INFINITY  </p>
    <p className='about-p'>For  Export Fresh and Frozen Agricultural Crops </p>
    </div>
    
    <div className='about-container'>
    <div className='about about1' >
    <span className='about-opacity'></span>
        <p className='about-header'>About Us</p>
        <p className='about-desc about-desc1'>{aboutData.about}</p>
    </div>
    <div className='about about2' >
        <p className='about-header'>Vission</p>
        <p className='about-desc about-desc2'>{aboutData.vision}</p>
    </div>
    <div className='about about3' >
        <p className='about-header'>Mission</p>
        <p className='about-desc about-desc3'>{aboutData.mission}</p>
    </div>
    <div className='about about4' >
        <p className='about-header'>Why Us</p>
        <p className='about-desc about-desc4'>{aboutData.whyOur}</p>
    </div>
    </div>
    </div>
  )
}
