import React from 'react'
import './Frozen.css'
import { frozenData} from '../../data/testimonialsData'
// import Contact from '../Contact/Contact'
import Modal from 'react-modal';

export const Frozen = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedFruitItem, setSelectedFruitItem] = React.useState(null);

  const handleOpenModal = (fruitItem) => {
    setSelectedFruitItem(fruitItem);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFruitItem(null);
  };
  
    return (
      <div className='frozen' id='frozen' style={{backgroundColor:"#FEFAE0"}}>
        <div className='fruitsContainer' id='frozenContainer' >
        <p className='fruitsHeader' id='frozenHeader'>Available Frozen</p>
              <div className='fruits-rows'>
              {frozenData ? (
                frozenData.map((fruitItem) => (
              <div className="veges-row" id="veges-row">
                <img
                  src={fruitItem.image[0]}
                  id="vegesImage"
                  onClick={() => handleOpenModal(fruitItem)}
                />
                <p id="vegesName" onClick={() => handleOpenModal(fruitItem)}>
                  {fruitItem.name}
                </p>
              </div>
            ))
          ) : (
            "NO Available Data Now"
          )}
        </div>
      </div>

      <Modal isOpen={showModal} onRequestClose={handleCloseModal} id="vegesModal">
        {selectedFruitItem && (
          <>
            <h2 className="ModalH">{selectedFruitItem.name}</h2>
            <img src={selectedFruitItem.image[0]} id="ModalImg" />
            <p className="ModalP">{selectedFruitItem.smDesc}</p>
            <button variant="secondary" onClick={handleCloseModal} id="ModalBtn">
              Close
            </button>
          </>
        )}
      </Modal>
      </div>
    )
}