import React from 'react'
import './Testimonials.css'
import {testimonialsData} from '../../data/testimonialsData'
import {VegetablesData} from '../../data/testimonialsData'
import { fruitsData } from '../../data/testimonialsData'
import { useState } from 'react'
import leftArrow from '../../img/leftArrow.png'
import rightAroow from '../../img/rightArrow.png'

import {motion} from 'framer-motion'



const Testimonials = () => {

    const [selected,setSelected]=useState(0)
    const tLenght =fruitsData.length
    const [selected2,setSelected2]=useState(0)
    const tLenght2 =VegetablesData.length


    const  transition={type:'spring', duration:3}

  return (
    <>
    <div className='Testimonials up-t'>
      <div className="right-t first-r">
            <motion.div
                 initial={{opacity: 0, x: -100}}
                 whileInView={{opacity: 1, x: 0}}
                 transition={{...transition, duration: 2}}
            ></motion.div>
             <motion.div
                 initial={{opacity: 0, x: 100}}
                 whileInView={{opacity: 1, x: 0}}
                 transition={{...transition, duration: 2}}
            ></motion.div>
            <motion.img
                 key={selected2}
                 initial={{opacity: 0, x: -100}}
                 animate={{opacity: 1, x: 0}}
                 exit={{opacity: 0, x: -100}}
                 transition={{transition}}
                src={VegetablesData[selected2].image[0]} alt="" />
            <div className="arrows  first-arrows">
                <img
                onClick={()=>(
                    selected2 === 0
                    ? setSelected2(tLenght2 -1)
                    : setSelected2((prev) =>prev -1)
                 )}
                src={leftArrow} alt="" />
                <img
                onClick={()=>{
                    selected2 === tLenght2-1
                    ? setSelected2((0))
                    : setSelected2((prev)=> prev +1)
                }}
                src={rightAroow} alt="" />
            </div>
        </div>
        <div className="left-t" id='left-t'>
            <span >Vegetables</span>
            <span className='stroke-text'></span>
            <span></span>
            <motion.span
                key={selected2}
                initial={{opacity: 0, x: 100}}
                animate={{opacity: 1, x: 0}}
                exit={{opacity: 0, x: 100}}
                transition={{transition}}                
            >{VegetablesData[selected2].smDesc}</motion.span>
            <span >
                <span className='veg-name' style={{color:'var(--orange)'}}>  
                  {VegetablesData[selected2].name}</span>
            </span>
        
        </div>
      
    </div>




    <div className='Testimonials up-d'>
        <div className="left-t second-l">
            <span className='title-fr'>Fruits</span>
            <span className='stroke-text'></span>
            <span></span>
            <motion.span
                key={selected}
                initial={{opacity: 0, x: -100}}
                animate={{opacity: 1, x: 0}}
                exit={{opacity: 0, x: 100}}
                transition={{transition}}                
            >{fruitsData[selected].smDesc}</motion.span>
            <span style={{color:'var(--orange)'}} id='fruitNaming' >
                 {fruitsData[selected].name}
            </span>
        
        </div>
        <div className="right-t second-r down-r">
            <motion.div
                 initial={{opacity: 0, x: -100}}
                 whileInView={{opacity: 1, x: 0}}
                 transition={{...transition, duration: 2}}
            ></motion.div>
             <motion.div
                 initial={{opacity: 0, x: 100}}
                 whileInView={{opacity: 1, x: 0}}
                 transition={{...transition, duration: 2}}
            ></motion.div>
            <motion.img
                 key={selected}
                 initial={{opacity: 0, x: 100}}
                 animate={{opacity: 1, x: 0}}
                 exit={{opacity: 0, x: -100}}
                 transition={{transition}}
                src={fruitsData[selected].image[0]} alt="" id='fr-imgs'/>
            <div className="arrows">
                <img
                onClick={()=>(
                    selected === 0
                    ? setSelected(tLenght -1)
                    : setSelected((prev) =>prev -1)
                 )}
                src={leftArrow} alt="" />
                <img
                onClick={()=>{
                    selected === tLenght-1
                    ? setSelected((0))
                    : setSelected((prev)=> prev +1)
                }}
                src={rightAroow} alt="" />
            </div>
        </div>
    </div>
    
    </>
  )
}

export default Testimonials