import React, { useState } from 'react';
import './Navbar2.css'
import Menu from '@iconscout/react-unicons/icons/uil-minus-square'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {fruitsData} from '../../data/testimonialsData'
import menu from "../../img/menu.webp"


import Logo from "../../img/logo.png"
import Bars from '@iconscout/react-unicons/icons/uil-bars'

const Navbar2 = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const navigate = useNavigate();

  
  const [selected,setSelected]=useState(0)
  const tLenght =fruitsData.length

  const [selectedOption, setSelectedOption] = useState('vegetables');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="menu-navbar">
      <div className="logo">
      <img src={Logo} id='Logoimg'/>
      </div>
      <div className="menu-toggle" onClick={handleMenuToggle}>
        <img src={menu} alt='Menu' style={{width:"40px", marginLeft:"-15px"}}/>
      </div>

      <nav className={`nav ${menuOpen ? 'active' : ''}`}>
        <ul>
        
                  <Link spy={true} to= '' smooth={true}  activeClass="activeClass">
                <li>Home</li>
                </Link>
                <Link spy={true} to="/orange"smooth={true} >
                <li >Orange</li>
                </Link>
                <Link spy={true} to="/fruits"smooth={true} >
                <li >Fruits</li>
                </Link>
                <Link spy={true} to="/vegetables"smooth={true} >
                <li >Vegetables</li>
                </Link>
               
                <Link spy={true} to= 'frozen' smooth={true} >
                <li>Frozen</li></Link>
                <Link spy={true} to= 'assessments' smooth={true} >
                <li>Assessments</li></Link>
             
            
        </ul>
        
      </nav>
      <Link to="contact-form" smooth={true} >
                 <button className="button n-button">
                    Contact 
                </button></Link>
    </div>
  );
};

export default Navbar2;
