import './Catigories.css'
import orangeImg from "../../img/ora.jfif"
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

import {testimonialsData} from '../../data/testimonialsData'
import { OrangeData } from '../../data/testimonialsData'
// import leftArrow from '../../img/leftArrow.png'
// import rightAroow from '../../img/rightArrow.png'



// import required modules
import { EffectCards } from 'swiper';
import { Link } from 'react-scroll'
import { Navigate, useNavigate } from 'react-router-dom'

export const Catigories = () => {
  const [selected,setSelected]=useState(0)
  const tLenght =testimonialsData.length 
  const  transition={type:'spring', duration:3}
  const navigate = useNavigate();
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigate('/orange'); // Navigate to the /about route
  };
  return (
    <div className='Catigory'>



    <div className='cat cat1'>
    <div className='cat-left'>
        <p className='cat-header '> Our Products</p>
        <p className='title title1'> Orange</p>
        <p  className='desc desc1'> 
        Oranges have a sweet and tangy flavor with a refreshing juiciness.
 The taste can vary slightly between different orange varieties.
Oranges are an excellent source of vitamin C, providing a significant portion of the recommended daily intake.

        </p>
        <Link spy={true} to="/orange"smooth={true} onClick={handleClick}>
               <button id='oraBtn'> Know More</button>
        </Link>
        
    </div>
    <div className='cat-right'>
        {/* <img src={vegetableImg} alt='Fruits'/> */}
        <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      >
      {}
        <SwiperSlide style={{width:"320px",height:"420px"}} >
        <img src={OrangeData.images[0]} style={{width:"310px",height:"410px"}}/>
        </SwiperSlide>
        <SwiperSlide style={{width:"320px",height:"420px"}} >
        <img src={orangeImg} style={{width:"310px",height:"410px"}}/>
        </SwiperSlide>
        <SwiperSlide style={{width:"320px",height:"420px"}} >
        <img src={OrangeData.images[1]} style={{width:"310px",height:"410px"}}/>
        </SwiperSlide>
        <SwiperSlide style={{width:"320px",height:"420px"}} >
        <img src={OrangeData.images[2]} style={{width:"310px",height:"410px"}}/>
        </SwiperSlide>
        <SwiperSlide style={{width:"320px",height:"420px"}} >
        <img src={OrangeData.images[3]} style={{width:"310px",height:"410px"}}/>
        </SwiperSlide>
      </Swiper>
    </div>

    </div>
    
    </div>
  )
}
