import React, { useEffect } from 'react'
import Slider from '../slider/Slider'
import { About } from '../About/About'
import { Catigories } from '../Catigories/Catigories'
import Testimonials from '../Testimonials/Testimonials'
import Contact from '../Contact/Contact'

export const Home = () => {
useEffect(()=>{
  document.title= "InfiniteCrops"
})
  return (
    <div className='Home'>

      <Slider />
      <About />
      <Catigories />
      <Testimonials />

      <Contact />
    </div>
  )
}
