import React from 'react'
import './Assessments.css'
import assessmentImg from "../../img/images/assessmentImage.PNG"
import assessmentImg2 from "../../img/images/assessmentImageIso9001.PNG"
import assessmentImg3 from "../../img/images/assessmentImage2.PNG"
import assessmentImg4 from "../../img/images/assessmentImage3.PNG"

export const Assessments = () => {
  return (
    <div className='assessments' id='assessments'>
    <h2>Assessments </h2>
         <div className='assessments-container'>
        
        <div className='ass-one'>
                <img src={assessmentImg}/>
                <a href='https://drive.google.com/file/d/1qAnfNR-6IoA-1vDQYqM_qN3pVkUmwpoR/view?usp=sharing' style={{color:"white"}} target='_blank' className='bb'> 
                <button className='ass-btn' >Open
                </button>
                </a>
            </div>
            <div className='ass-one'>
                <img src={assessmentImg2}/>
                <a href='https://drive.google.com/file/d/1qAnfNR-6IoA-1vDQYqM_qN3pVkUmwpoR/view?usp=sharing' style={{color:"white"}} target='_blank' className='bb'> 
                <button className='ass-btn' >Open
                </button>
                </a>
            </div>
            
            
            <div className='ass-one'>
                <img src={assessmentImg3}/>
                <a href='https://drive.google.com/file/d/1qAnfNR-6IoA-1vDQYqM_qN3pVkUmwpoR/view?usp=sharing' style={{color:"white"}} target='_blank' className='bb'> 
                <button className='ass-btn' >Open
                </button>
                </a>
            </div>
            <div className='ass-one'>
                <img src={assessmentImg4}/>
                <a href='https://drive.google.com/file/d/1qAnfNR-6IoA-1vDQYqM_qN3pVkUmwpoR/view?usp=sharing' style={{color:"white"}} target='_blank' className='bb'> 
                <button className='ass-btn' >Open
                </button>
                </a>
            </div>
        </div>
       

    </div>
  )
}
