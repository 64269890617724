
import { Swiper, SwiperSlide } from 'swiper/react';
// import fruitImg from "../../img/fruit-img.jpg"
// import orangeImg from "../../img/ora.jfif"
// import vegetableImg from "../../img/veg.jfif"
// import Main1 from "../../img/main1.png"
// import Main2 from "../../img/main2.png"
// import Main3 from "../../img/main3.png"
// import Main4 from "../../img/main4.png"
// import fruit2Img from "../../img/a15.png"
import React, { useRef, useState } from 'react';
import { SliderData } from '../../data/testimonialsData';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import { EffectFade } from 'swiper/modules';


import 'swiper/css/effect-fade';

import './Slider.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';

export default function Slider() {
  return (
    <div className='SliderMain'>
      <Swiper

        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,

        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper2"
      >
      {/* <div>
        <p className='slider-p'>d'vamvdenvdenvivo</p>
        <span className='slider-s'></span>
        </div> */}
        {/* <SwiperSlide className='sl-swiper-slide'>
          <img src={Main2} style={{ width: "100vw", height: "38vw" }} />
        </SwiperSlide> */}
        {SliderData ? SliderData.map((slide)=>(
          <SwiperSlide className='sl-swiper-slide' /*style={{animation:" myAnim 2s ease 0s 1 normal forwards"}}*/ >
        <div className='sl-slider' >
        <img src={slide.image} style={{width:"100dvw",height:"38dvw"}}/>
        <span className='sl-opacity'></span>
        <p className='slider-p'>{slide.review}</p>
        {/* <span className='slider-s' style={{backgroundColor:`${slide.bgColor}`}}></span> */}
        <span className='slider-s2' ></span>
        </div>
        </SwiperSlide>
        )):"No data available"}

      </Swiper>
    </div>
  );
}
