
import '../src/App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./component/Footer/Footer";
import { One } from "./component/One/One";
import { Home } from "./component/Home/Home";
import { Fruits } from "./component/Fruits/Fruits";
import { Vegetables } from "./component/Vegetables/Vegetables";
import { Assessments } from "./component/Assessments/Assessments";

import { Frozen } from "./component/Frozen/Frozen";
import Navbar2 from "./component/Navbar2/Navbar2";



function App() {
  return (
     <BrowserRouter>
    <div className="App">
    <Navbar2 />
        <Routes>
          <Route  path="/" element={<Home />}>
          </Route>
          <Route  path="/orange" element={<One />}>
          </Route>
          <Route  path="/fruits" element={<Fruits />}>
          </Route>
          <Route  path="/vegetables" element={<Vegetables />}>
          </Route>
          <Route  path="/assessments" element={<Assessments />}>
          </Route>
          <Route  path="/frozen" element={<Frozen />}>
          </Route>
          
        </Routes>
        <Footer />

    </div>
      </BrowserRouter> 
  );
}

export default App;
