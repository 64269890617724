import React from 'react'
import './Footer.css'

import Whatsapp from '@iconscout/react-unicons/icons/uil-whatsapp'
import RightArrow from '@iconscout/react-unicons/icons/uil-arrow-circle-right'
import Pin from '@iconscout/react-unicons/icons/uil-location-pin-alt'
import Pin2 from '@iconscout/react-unicons/icons/uil-map-pin-alt'
import Mobile from '@iconscout/react-unicons/icons/uil-mobile-android'
import Mail from '@iconscout/react-unicons/icons/uil-fast-mail'
import Copyright from '@iconscout/react-unicons/icons/uil-copyright'
import img1 from "../../img/a10.jpeg"
import img2 from "../../img/a11.jpeg"
import img3 from "../../img/a12.jpeg"
import img4 from "../../img/a13.jpeg"
import { Link } from 'react-scroll'
import { useNavigate } from 'react-router-dom'
const Footer = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigate('/orange'); // Navigate to the /about route
  };
  const fruitsHandleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigate('/fruits'); // Navigate to the /about route
  };
  const homeHandleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigate('/'); // Navigate to the /about route
  };
  const vegetablesHandleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigate('/vegetables'); // Navigate to the /about route
  };
  return (
    <div className="footer">
 
      <div className='footer-container'>
        <div className='f-left1'>
          <p className='header'>ABOUT</p>
          <div className='desc'>
            <Pin2 color='var(--orange)' size='4rem' />
            <p style={{ marginTop: "0.8rem" ,fontFamily:"Gill Sans, sans-serif"}}> Infinity Agro Coompany has an established network with various channel partners and corporate associates. The company is committed to providing high-quality agro products and services to its customers.</p>  { /* نبذة بسيطة عن الشركة والاصناف*/}
          </div>
          <div className='location'>
            <Pin color='var(--orange)' size='1rem' />
            <p style={{ fontFamily:"Gill Sans, sans-serif"}}>151 Ebaid st,Al Sahel, Cairo</p> { /*موقع الشركة */}
          </div>
        </div>

        <div className='f-left2'>
          <p className='header'>CONTACTS</p>
          <ul>
          <div className='left2-row'>
          <Mobile color='var(--orange)' size='1rem' />
          <li>+20 10 3280 0061</li>
          </div>
          <div className='left2-row' >
          <Mail color='var(--orange)' size='1rem' />
          <li>Sales@infinitecrops.com</li>
          </div>
          <div className='left2-row' >
          <Whatsapp color='var(--orange)' size='1rem' />
          <li>+20 10 0306 6624</li>
          </div>
            
          </ul>

        </div>

        <div className='f-right1'>
          <p className='header'>SITEMAP</p>
          <div className='f-right1-body'>
          <ul>
            <Link spy={true} to= 'Navebar' smooth={true} onClick={homeHandleClick} activeClass="activeClass">
                <li><RightArrow color='var(--orange)'   /> Home</li>
                </Link>
                <Link spy={true} to="/orange"smooth={true} onClick={handleClick}>
                <li > <RightArrow color='var(--orange)'   /> Orange</li>
                </Link>
                <Link spy={true} to="/fruits"smooth={true} onClick={fruitsHandleClick}>
                <li > <RightArrow color='var(--orange)'  /> Fruits</li>
                </Link>
                <Link spy={true} to="/one"smooth={true} onClick={vegetablesHandleClick}>
                <li > <RightArrow color='var(--orange)'  /> Vegetables</li>
                </Link>
          </ul>
          </div>
        </div>
        <div className='f-right2'>
          <p className='header'>PHOTOS</p>
          <div className='photos'>
            <div className='photo f-photoUp'>
              <img src={img1} />
              <img src={img2} />
            </div>
            <div className='photo f-photoDown'>
              <img src={img3} />
              <img src={img4} />
            </div>
          </div>
        </div>
      </div>
      <div className='footer-end'>
        <div className='end-left'>Copyright <Copyright marginTop='20px' color='white' size='0.9rem' />2023 All rights reserved</div>
        <div className='end-right'>Website is made by <span id='teamName'>MS Team</span> </div>
      </div>
    </div>
  )
}

export default Footer