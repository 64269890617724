import React from 'react';
import './Vegetables.css';
import { VegetablesData } from '../../data/testimonialsData';
import Modal from 'react-modal';

export const Vegetables = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedFruitItem, setSelectedFruitItem] = React.useState(null);

  const handleOpenModal = (fruitItem) => {
    setSelectedFruitItem(fruitItem);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFruitItem(null);
  };

  return (
    <div className="vegetables" id="vegetables">
      <div className="vegesContainer">
        <p className="vegesHeader" id='vegesHeader'>Available Vegetables</p>
        <div className="veges-rows" id="veges-rows">
          {VegetablesData ? (
            VegetablesData.map((fruitItem) => (
              <div className="veges-row" id="veges-row">
                <img
                  src={fruitItem.image[0]}
                  id="vegesImage"
                  onClick={() => handleOpenModal(fruitItem)}
                />
                <p id="vegesName" onClick={() => handleOpenModal(fruitItem)}>
                  {fruitItem.name}
                </p>
              </div>
            ))
          ) : (
            "NO Available Data Now"
          )}
        </div>
      </div>

      <Modal isOpen={showModal} onRequestClose={handleCloseModal} id="vegesModal">
        {selectedFruitItem && (
          <>
            <h2 className="ModalH">{selectedFruitItem.name}</h2>
            <img src={selectedFruitItem.image[0]} id="ModalImg" />
            <p className="ModalP">{selectedFruitItem.smDesc}</p>
            <p className="ModelP2">{selectedFruitItem.available}</p>
            <button variant="secondary" onClick={handleCloseModal} id="ModalBtn">
              Close
            </button>
          </>
        )}
      </Modal>
    </div>
  );
};